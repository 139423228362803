.onboarding-screen-main-container {
  width: 100%;
  margin: 0px auto;
  min-height: 100vh;
  display: flex;
  background-color: #fcf2d8;
}

.onboarding-box {
  padding-left: 25px;
  padding-right: 25px;
  max-width: 100%;
  min-height: 70vh;
  background-color: #fffcf8;
  border-radius: 25px;
  box-shadow: 1px 1px 9px 1px #ececec;
  margin-top: 100px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-bottom: 25px;
}

.onboarding-box-heading {
  font-size: 18px;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  margin-top: 15px;
  margin-bottom: 5px;
}

.onboarding-box-Image {
  height: 230px;
  max-width: 100%;
  max-height: 90%;
  margin-top: 200px;
  z-index: 99;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 380px;
  margin-left: 215px;
}

.onboarding-box-Image h2 {
  font-size: 50px;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  margin-bottom: 10px;
}

.onboarding-box-Image p {
  font-size: 20px;
  font-weight: 400;
  font-family: "Quicksand";
  color: #394b42;
  margin-bottom: 70px;
}

.onboarding-box-Image img {
  width: 100%;
  height: 230px;
  object-fit: contain;
  right: 0px;
}

.btn-Continuemain {
  width: 660px;
  margin-top: 30px;
  padding-bottom: 20px;
}

.btn-Continuemain button {
  width: 100%;
  height: 45px;
  border-radius: 50px;
  background-color: #49af7c;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-family: "Quicksand";
  position: relative;
}

.options-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-content: center;
  margin: 20px;
}

.option-button {
  padding: 10px 15px;
  border-radius: 50px;
  background-color: #fffcf8;
  box-shadow: 1px 1px 10px 1px #ececec;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  color: #394b42;
}

.option-button:hover {
  background-color: #C4EED9;
  color: #394b42;
}

.option-button.selected {
  background-color: #C4EED9;
  color: #394b42;
  box-shadow: 1px 1px 10px 1px #ececec;
}

.sub-heading {
  font-size: 14px;
  color: gray;
  margin-bottom: 10px;
}

.progress-indicator1 {
  display: flex;
  gap: 8px;
  margin-top: 25px;
  align-self: flex-start;
}

.step1 {
  width: 55px;
  height: 6px;
  background-color: #ddd;
  border-radius: 5px;
  transition: background-color 0.5s ease-in-out;
}

.active1 {
  background-color: #49af7c;
}

.highlightedText {
  text-decoration: underline;
  font-weight: 800;
}

.signout-modal{
  font-family: "Quicksand", sans-serif;
  z-index: 999999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFCF8;
  width: 360px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 50px;
}

.signout-main-head {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.signout-head{
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.sbtns{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  width:90%;
  align-self: center;
}
.signout-btn{
  background: #49AF7C;
  color:#FFFCF8;
  font-size: 15px;
  font-weight: 500;
  width:45%;
  height:40px;
  border-radius: 32px;
  border:none;
  outline:none;
}
.signout-btn.no{
  background: #C4EED9;
  color:#394b42;
  font-weight: 500;
}

.mobileScree-onboarding-screen-main-container {
  width: 100%;
  margin: 0px auto;
  min-height: 100vh;
  background-color: #FCF2D8;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobileScreen-onboarding-heading {
  font-size: 25px;
  font-weight: 600;
  font-family: "Quicksand";
  color: #394b42;
  padding-top: 105px !important;
  margin-bottom: 5px;
}

.mobileScreen-onboarding-btn-Continuemain {
  width: 90%;
  margin-top: 20px;
  bottom: 45px;
}

.mobileScreen-onboardings-btn-Continuemain button {
  width: 100%;
  height: 45px;
  border-radius: 50px;
  background-color:#49AF7C;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-family: "Quicksand";
}